<template lang="pug">
.page
	Menu
		template(#title)
			.flex.items-center
				| Competitors

	template(v-if="isMounted")
		.control-panel
			BaseFilter.filter(
				name="Competitors"
				:items="selectItems"
				@update="updateFilter"
			)

			.labels
				.label
					.box.box-green
					.text Наши бренды
				.label
					.box.box-gray
					.text Конкуренты

		.loader(v-if="isLoading")
			| Пожалуйста, подождите...
		.widgets(v-else)
			.widget(v-for="widget in widgets" :key="widget.widgetId")
				.title {{ widget.name }}
				.main-value {{ widget.value }}
				.secondary-value {{ widget.competitorValue }}
				Tooltip.tooltip
					template(slot="reference")
						IconInfo.icon
					.tolltip-text
						.tooltip-title {{ widget.name }}
						.tooltip-description {{ widget.description }}

	.empty-filter(v-else) Пожалуйста, выберите значения фильтра

</template>

<script>
import Menu from '@/components/Menu/Menu.vue'
import Tooltip from '@/components/Elements/Tooltip.vue'
import IconInfo from '@/assets/svg/IconInfo.svg'
import BaseFilter from '@/components/Nestle/GlobalFilter/Filter.vue'

export default {
	name: "ContentIntegrity",

	components: {
		Menu,
		Tooltip,
		IconInfo,
		BaseFilter,
	},

	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			isMounted: false,
			isLoading: false,
			dataError: '',

			selectItems: {
				name: 'Конкуренты',
				values: [],
			},

			widgets: [],
		}
	},

	computed: {
		competitorsBrandsIds() {
			const checkedBrands = this.selectItems.values.filter((item) => item.checked);
			return checkedBrands.map((brand) => brand.id);
		}
	},

	methods: {
		async updateFilter() {
			await this.fetchCompetitorsWidgetsData();
		},

		async fetchCompetitorsBrands() {
			this.isLoading = true;
			this.dataError = '';

			try {
				const response = await this.$api.common.getCompetitorsBrands();

				if (response && response.brands && Array.isArray(response.brands)) {
					this.selectItems.values = response.brands.map((brand) => {
						return {
							id: brand.id,
							name: brand.name,
							checked: true,
						}
					})
				} else {
					this.selectItems.values = [];
				}
			} catch {
				this.dataError = 'Произошла ошибка при загрузке данных.';
			}

			this.isLoading = false;
		},

		async fetchCompetitorsWidgetsData() {
			this.isMounted = true;
			this.isLoading = true;
			this.dataError = '';

			const params = {
				stores: this.stores.split(','),
				categories: this.categories.split(','),
				brands: this.brands.split(','),
				regions: this.regions.split(','),
				competitorsBrands: this.competitorsBrandsIds,
				dateTo: this.dateTo,
				dateFrom: this.dateFrom,
			};

			try {
				const response = await this.$api.common.getCompetitorsWidgetsData(params);

				if (response && response.widgets && Array.isArray(response.widgets)) {
					this.widgets = response.widgets;
				} else {
					this.widgets = [];
				}
			} catch {
				this.widgets = [];
				this.dataError = 'Произошла ошибка при загрузке даных для виджетов';
			}

			this.isLoading = false;
		}
	},

	watch: {
		async needUpdateResult() {
			await this.fetchCompetitorsBrands();
			await this.fetchCompetitorsWidgetsData();
		},
	},
}
</script>

<style lang="scss" scoped>
.page {
	max-width: 1280px;
	margin: 0 auto;
}
.empty-filter, .loader {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.widgets {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	margin-top: 24px;
	margin-left: 24px;
    margin-right: 24px;
}
.tooltip {
	display: none;
	position: absolute;
    left: 14px;
    cursor: pointer;
	min-width: 100%;
	&-text {
		padding: 24px;
		box-shadow: 0px 1px 10px 0px #00000033;
		box-shadow: 0px 4px 5px 0px #0000001F;
		box-shadow: 0px 2px 4px 0px #00000024;
	}
	&-title {
		font-family: 'OpenSans';
		font-size: 16px;
		font-weight: 600;
	}
	&-description {
		margin-top: 4px;
		font-family: 'Montserrat';
	}
}
.widget {
	display: flex;
	flex-direction: column;
    align-items: flex-end;
	position: relative;
	font-family: 'OpenSans';
	color: #333333;
	width: 290px;
	height: 137px;
	padding-top: 14px;
	padding-right: 16px;
	padding-left: 42px;
	padding-bottom: 16px;
	background-color: #ffffff;
	border: 1px solid #E0E0E0;
	border-radius: 4px;
	&:after {
		content: '';
		position: absolute;
		height: 8px;
		bottom: 0;
		left: 0;
		width: 35%;
		background-color: #67AC5B;
		border-bottom-left-radius: 4px;
	}
	&:before {
		content: '';
		position: absolute;
		height: 8px;
		bottom: 0;
		right: 0;
		width: 65%;
		background-color: #BDBDBD;
		border-bottom-right-radius: 4px;
	}
	&:hover {
		& > .tooltip {
			display: block;
		}
	}
}

.title {
	text-align: right;
	font-size: 14px;
	color: #333333;
}
.main-value {
	margin-top: 6px;
	font-size: 24px;
	color: #67AC5B;
}
.secondary-value {
	font-size: 18px;
	color: #6A6A6A;
}

.icon {
	width: 16px;
	height: 16px;
}
.control-panel {
	display: flex;
	margin-top: 24px;
	margin-bottom: 24px;
	margin-left: 24px;
}
.filter {
	display: flex;
}
::v-deep .filter__label {
	font-family: 'OpenSans';
	margin-left: 0;
	color: #747474;
	background-color: #ffffff;
}
.labels {
	display: flex;
	margin-left: 24px;
}
.box {
	display: flex;
	width: 40px;
	height: 16px;
	margin-right: 7px;
	&-green {
		background-color: #67AC5B;
	}
	&-gray {
		background-color: #BDBDBD;
	}
}

.label {
    display: flex;
    align-items: center;
	color: #828E9D;
	&:not(:last-of-type) {
		margin-right: 12px;
	}
}

::v-deep .popper {
    margin-left: 74px;
} 
</style>
